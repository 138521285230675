import "./styles.css";
import "./tailwind_output.css";
import { ENV } from "./env.js";
import { getUser, updateUser, getStocks } from "./service";

import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Combobox, Disclosure, Menu, Transition } from "@headlessui/react";
import { CogIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signOut, deleteUser } from "firebase/auth";

const TITLE = "Account Settings | InsightList";

var firebaseui = require('firebaseui');
const firebaseConfig = {
  apiKey: ENV['API_KEY'],
  projectId: ENV['PROJECT_ID'],
  authDomain: ENV['DOMAIN']
}
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const deliveryDays = [
  { id: "sun", title: "Sunday", val: 6 },
  { id: "mon", title: "Monday", val: 0 },
  { id: "tue", title: "Tuesday", val: 1  },
  { id: "wed", title: "Wednesday", val: 2  },
  { id: "thu", title: "Thursday", val: 3  },
  { id: "fri", title: "Friday", val: 4  },
  { id: "sat", title: "Saturday", val: 5  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export class App extends React.Component {

  constructor(props) {
    super(props);
    this.query = "";
    this.location = "/account";

    this.userNavigation = [
      {name: "Account Settings", href: "#"},
      {name: "Sign out", href: "#", click: () => this.signOut()}
    ];

    this.subNavigation = [
      {name: "Account Settings", href: "#", icon: CogIcon, current: true}
    ];

    this.authUser = {
      displayName: "",
      email: "",
      photoURL: "",
    }

    var user = {
      displayName: "",
      email: "",
      photoURL: "",
      image_url: "",
      favorite_stocks: [],
      favorite_stock_names: [],
      delivery_days_of_week: []
    };

    this.state = {
      user: user,
      authUser: this.authUser,
      idToken: null,
      auth: null,
      filteredStocks: []
    }

    var _this = this;
    $(document).ready(function() {
      onAuthStateChanged(auth, (_authUser) => {
        var authUser = _authUser;
        _this.authUser = authUser;
        if (!authUser) {
          window.location.href = ENV["LP_URL"];

          var ui = new firebaseui.auth.AuthUI(auth);
          const uiConfig = {
            callbacks: {
              signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                return true;
              },
            },
            signInFlow: 'popup',
            signInSuccessUrl: _this.location,
            signInOptions: [
              GoogleAuthProvider.PROVIDER_ID,
            ],
          };
          ui.start('#auth', uiConfig);
          return
        }

        if (!authUser.favorite_stock_ids) {
          authUser.favorite_stock_ids = [];
        }

        const idToken = authUser.toJSON().stsTokenManager.accessToken;
        const apiKey = authUser.toJSON().stsTokenManager.apiKey;
        const refreshToken = authUser.toJSON().stsTokenManager.refreshToken;
        const accessToken = authUser.toJSON().stsTokenManager.accessToken;
        const userId = authUser.toJSON().uid;
        getUser(idToken).then(_user => {
          var user = _user;
          _this.updateState(user, authUser, _this.filterdStocks, idToken, auth);
        });
        // Cloud Function上でのキャッシュ取得のためのリクエスト
        // _this.setFilteredStocks(idToken);
        getStocks(idToken, 100000).then(stocks=> {
          _this.stocks = stocks;
          _this.setFilteredStocks();
        });
      });
    });
  }

  setFilteredStocks() {
    const lowerCaseQuery = this.query.toLowerCase();
    this.filteredStocks =
        this.query === ""
          ? this.stocks
          : this.stocks.filter((stock) => {
              if (!!stock && !!stock.name && !this.state.user.favorite_stock_names.includes(stock.name)) {
                return (stock.name+' '+stock.symbol).toLowerCase().includes(lowerCaseQuery);
              } else {
                return false;
              }
            });
    this.filteredStocks = this.filteredStocks.slice(0, 100);
    this.updateFilteredStocksState(this.filteredStocks);
  }

  setAddedStocks(stocks) {
    this.state.user.favorite_stocks = stocks;
    this.updateUserState(this.state.user);
  }
  setQuery(query) {
    this.query = query;
    this.setFilteredStocks();
  }
  setStocks() {
  }

  setSelectedStock(stock) {
    console.log('setSelectedStock()', stock);
    this.state.user.favorite_stocks.push(stock);
    this.state.user.favorite_stock_ids.push(stock.id);
    this.updateUserState(this.state.user);
    updateUser(this.state.user, this.state.idToken);
  }

  updateDeliveryDay(e) {
    console.log('e', e);
    const e_val = parseInt(e.target.id);
    if (this.state.user.delivery_days_of_week.includes(e_val)) {
      this.state.user.delivery_days_of_week = this.state.user.delivery_days_of_week.filter((val) => {
        return val !== e_val;
      });
    } else {
      this.state.user.delivery_days_of_week.push(e_val);
    }
    this.updateUserState(this.state.user);
    updateUser(this.state.user, this.state.idToken);
  }

  updateFilteredStocksState(filteredStocks) {
    this.updateState(this.state.user, this.state.authUser, filteredStocks, this.state.idToken, this.state.auth);
  }

  updateUserState(user) {
    this.updateState(user, this.state.authUser, this.filteredStocks, this.state.idToken, this.state.auth);
  }

  updateAuthState(auth) {
    this.updateState(this.state.user, this.state.authUser, this.filteredStocks, this.state.idToken, auth);
  }

  updateState(user, authUser, filteredStocks, idToken, auth) {
    user.favorite_stock_names = user.favorite_stocks.map((stock) => stock.name);
    this.setState({
      user: user,
      authUser: authUser,
      idToken: idToken,
      auth: auth,
      filteredStocks: filteredStocks
    })
  }

  deleteUser() {
    deleteUser(this.state.auth.currentUser).then(() => {
      window.location.href = ENV["LP_URL"];
    }).catch((error) => {
      console.error(`An error occurred deleting account. (${error})`);
    })
  }

  signOut() {
    signOut(this.state.auth).then(() => {
      window.location.href = ENV["LP_URL"];
    }).catch((error) => {
      console.error(`An error occurred logging out. (${error})`);
    })
  }

  render () {
    return <>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <div className="h-full bg-gray-50">
        <Disclosure as="header" className="bg-white shadow">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                <div className="relative h-16 flex justify-between">
                  <div className="relative z-10 px-2 flex lg:px-0">
                    <div className="flex-shrink-0 flex items-center">
                      <a
                        href="https://www.vcaware.com/"
                        className="font-bold text-gray-800 text-xl"
                      >
                        VC Aware
                      </a>
                    </div>
                  </div>
                  <div className="relative z-10 flex items-center lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="flex-shrink-0 relative ml-4">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex items-center">
                          <span className="sr-only">Open user menu</span>
                          <div className="mr-3 text-right">
                            <div className="text-sm font-medium text-gray-800">
                              {this.state.user.name}
                            </div>
                            <div className="text-xs font-medium text-gray-400">
                              {this.state.user.email}
                            </div>
                          </div>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={this.state.user.photo_url}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                          {this.userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  onClick={item.click}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
              <Disclosure.Panel
                as="nav"
                className="lg:hidden"
                aria-label="Global"
              >
                <div className="border-t border-gray-200 pt-4 pb-3">
                  <div className="px-4 flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={this.state.user.photo_url}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {this.state.user.name}
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        {this.state.user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {this.userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        onClick={item.click}
                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
              <nav className="space-y-1">
                {this.subNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-50 text-gray-700 hover:bg-gray-100"
                        : "text-gray-400 hover:text-gray-800 hover:bg-gray-100",
                      "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-700"
                          : "text-gray-400 group-hover:text-gray-600",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside>
            {/* Account details */}
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              {/* Delivery preference */}
              <section aria-labelledby="delivery-preference">
                <form action="#" method="POST">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                      <div>
                        <h2 className="mb-1 text-lg leading-6 font-medium text-gray-900">
                          Delivery preference
                        </h2>
                        <div className="mb-6">
                          <p className="text-sm text-gray-500">
                            Manage your newsletter delivery preference.
                          </p>
                        </div>
                        <form action="#" className="mt-6 flex">
                          <div className="">
                            {deliveryDays.map((deliveryDay) => (
                              <div
                                key={deliveryDay.id}
                                className="inline-flex items-center mr-5"
                              >
                                <input
                                  id={deliveryDay.val}
                                  name="notification-method"
                                  type="checkbox"
                                  checked={this.state.user.delivery_days_of_week.includes(deliveryDay.val)}
                                  // defaultChecked
                                  // defaultChecked={this.state.user.delivery_days_of_week.includes(deliveryDay.val)}
                                  onChange={(e) => {
                                    this.updateDeliveryDay(e);
                                  }}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                />
                                <label
                                  htmlFor={deliveryDay.id}
                                  className="ml-2 block text-sm font-medium text-gray-700"
                                >
                                  {deliveryDay.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              {/* Account */}
              <section aria-labelledby="account">
                <form action="#" method="POST">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                      <div>
                        <h2 className="mb-1 text-lg leading-6 font-medium text-gray-900">
                          Account
                        </h2>
                        <div className="mb-6">
                          <p className="text-sm text-gray-500">
                            Manage your connected account and configurations.
                          </p>
                        </div>
                      </div>
                      {/* Place "Sign in with Google" button here */}
                      {/* <img src="https://developers.google.com/identity/images/btn_google_signin_light_normal_web.png" /> */}
                      <div id="auth"></div>
                      <div className="mt-10 p-6 rounded-md bg-gray-50">
                        <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide mb-5">
                          Connected account
                        </h3>
                        <ul className="border-t border-b border-gray-200 divide-y divide-gray-200">
                          {[this.state.authUser].map((account, accountIdx) => (
                            <li
                              key={accountIdx}
                              className="py-4 flex items-center justify-between space-x-3"
                            >
                              <div className="min-w-0 flex-1 flex items-center space-x-3">
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={account.photoURL}
                                    alt=""
                                  />
                                </div>
                                <div className="min-w-0 flex-1">
                                  <p className="text-sm font-medium text-gray-900 truncate">
                                    {account.displayName}
                                  </p>
                                  <p className="text-sm font-medium text-gray-500 truncate">
                                    {account.email}
                                  </p>
                                </div>
                              </div>
                              <div className="flex-shrink-0">
                                <button
                                  type="button"
                                  onClick={(e) => { if (confirm("Are you sure want to delete account?")) {this.deleteUser()}} }
                                  className="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  <XIcon
                                    className="mr-1 h-4 w-4 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="text-sm font-medium text-gray-800">
                                    Delete Account{" "}
                                    <span className="sr-only">
                                      {account.name}
                                    </span>{" "}
                                  </span>
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  }
}


import "./styles.css";
// import "./tailwind_output.css";
import "./tailwind.min.css";
import { ENV } from "./env.js";
import HeroImage from "./img/hero@2x.png";
import GoogleLogoIcon from "./img/icon-google.png";

import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, onAuthStateChanged, GoogleAuthProvider, signOut } from "firebase/auth";

const TITLE = "VC Aware - Insights from venture capitalists, delivered every day.";

var firebaseui = require('firebaseui');
const firebaseConfig = {
  apiKey: ENV['API_KEY'],
  projectId: ENV['PROJECT_ID'],
  authDomain: ENV['DOMAIN']
}
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export class LP extends React.Component {

  constructor(props) {
    super(props);
    $(document).ready(function() {
      $(".sign-in-with-google").on("click", function() {
        signInWithPopup(auth, provider).then((result) => {
					const credential = GoogleAuthProvider.credentialFromResult(result);
					const token = credential.accessToken;
					const user = result.user;
					if(user !== null) location.href = "/account";
				}).catch((error) => {
					const errorCode = error.code;
					const errorMessage = error.message;
					const credential = GoogleAuthProvider.credentialFromError(error);
				});
      });
    });
  }

  render () {
    return <>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <div className="relative bg-white overflow-hidden">
        <div className="relative pt-6 pb-8 sm:pb-12 lg:pb-16">
          <div>
            <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="index.html">
                    <span className="text-3xl font-extrabold text-gray-900">VC Aware</span>
                  </a>
                </div>
              </div>
              <div className="hidden md:block text-right">
                <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                  <a href="#" className="sign-in-with-google inline-flex items-center px-4 py-2 border border-transparent text-base font-semibold rounded-md text-blue-700 bg-white hover:bg-gray-50">Subscribe</a>
                </span>
              </div>
            </nav>
          </div>
          <main className="mt-12 mx-auto max-w-7xl px-4 sm:mt-18 sm:px-6 lg:mt-24">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="mt-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <h1>
                  <span className="mt-1 block text-3xl tracking-tight font-extrabold sm:text-4xl xl:text-5xl">
                    <span className="text-gray-900">Insights from VCs, {" "}</span>
                    <span className="text-blue-700">delivered every day.</span>
                  </span>
                </h1>
                <p className="mt-3 text-sm text-gray-500 sm:mt-5 sm:text-lg lg:text-base xl:text-lg">VC Aware is a daily newsletter of readworthy articles and engaging contents tweeted from industry-leading influential venture capitalists.</p>
                <div className="mt-8 sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                  <button type="button" id="sign-in-with-google" className="sign-in-with-google inline-flex w-full items-center px-7 py-5 border border-gray-300 shadow-md text-xl font-semibold rounded-md text-gray-600 bg-white hover:bg-gray-100 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300">
                    <img src={ GoogleLogoIcon } className="w-6 mr-4" />
                    <span className="w-full text-center">Subscribe for free with Google</span>
                  </button>
                  <p className="mt-3 text-xs text-gray-500">
                    We care about the protection of your data. Read our
                    {" "}
                    <a href="#" className="font-medium text-gray-700 underline">terms & policy</a>.
                  </p>
                </div>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto w-full">
                  <img src={ HeroImage } className="w-full" />
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="relative bg-white py-8 sm:py-12 lg:py-16">
          <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <p className="mt-2 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Know what happens in the markets and what people think about it.</p>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-green-500 rounded-md shadow-lg">
                          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"></path></svg>
                        </span>
                      </div>
                      <h3 className="mt-8 text-2xl font-bold text-gray-700 tracking-tight">Readworthy Everyday</h3>
                      <p className="mt-5 text-base font-light text-gray-500">An E-mail is delivered to your inbox every day (or your prefered days), with engagement-based content optimization.</p>
                    </div>
                  </div>
                </div>
                <div className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-green-500 rounded-md shadow-lg">
                          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                        </span>
                      </div>
                      <h3 className="mt-8 text-2xl font-bold text-gray-700 tracking-tight">Digest & Discovery</h3>
                      <p className="mt-5 text-base font-light text-gray-500">Stay on top of the must-know market moves with insightful analysis, researches, useful resources or contents, shared by top VCs.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
                <p className="mt-4 font-light text-gray-500 tracking-tight">Can’t find the answer you’re looking for? Reach out to our <a href="mailto:support@vcaware.com" className="font-medium text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-8">
                  <div>
                    <dt className="text-lg leading-6 font-medium text-gray-900 tracking-tight">When is an E-mail delivered?</dt>
                    <dd className="mt-1 text-base font-light text-gray-500 tracking-tight">Every day, or you can customize which days of the week an E-mail will be delivered.</dd>
                  </div>
                  <div>
                    <dt className="text-lg leading-6 font-medium text-gray-900 tracking-tight">Do I need to make a payment?</dt>
                    <dd className="mt-1 text-base font-light text-gray-500 tracking-tight">No. VC Aware delivers an E-mail for free.</dd>
                  </div>
                  <div>
                    <dt className="text-lg leading-6 font-medium text-gray-900 tracking-tight">How can I change my registered E-mail address?</dt>
                    <dd className="mt-1 text-base font-light text-gray-500 tracking-tight">You can always change your E-mail address on your account management screen after sign-in.</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6">
            <div className="bg-indigo-700 rounded-lg shadow-xl">
              <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
                <h2 className="text-2xl font-bold text-white sm:text-3xl">
                  <span className="block">Ready to dive in? Start reading today.</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-indigo-200">Never miss the actual voices of top-tier venture capitalists. Never miss the opportunities and the market moves.</p>
                <a href="#" className="sign-in-with-google mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto">Subscribe for free</a>
              </div>
            </div>
          </div>
        </div>
        <footer className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
              <div className="px-5 py-2">
                <a href="#" className="text-base text-gray-500 hover:text-gray-900">Terms</a>
              </div>
              <div className="px-5 py-2">
                <a href="mailto:support@vcaware.com" className="text-base text-gray-500 hover:text-gray-900">Contact</a>
              </div>
            </nav>
            <p className="mt-8 text-center text-sm text-gray-400">Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | Handmade in and with love from San Francisco.</p>
          </div>
        </footer>
      </div>
    </>
  }
}

